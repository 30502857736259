<template>
  <master-detail
    asyncExportType="sigPlani"
    :canDelete="false"
    :canEdit="false"
    :canView="true"
    :cols="cols"
    :customResource="customResource"
    :formTitle="formTitle"
    :formWidth="900"
    :hasExportCSV="false"
    :hasMonthlyFilter="true"
    :hasNewButton="false"
    :opts="opts"
  ></master-detail>
</template>

<script>
import _ from "lodash";
import { mapGetters } from 'vuex';
export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId']),
    formTitle: function () {
      return this.$route.meta.pageTitle;
    },
    resourceUrl: function () {
      return `/v1/faturamento/produtoscalc/${this.clientId}`;
    },
    resource: function () {
      return this.apiResource(this.resourceUrl);
    },
    customResource: function () {
      return {
        get: (param) => {
          return new Promise((resolve, reject) => {
            this.resource
              .get(param)
              .then((result) => {
                resolve(this.acumulate(result));
              })
              .catch(reject);
          });
        },
      };
    },
  },
  methods: {
    acumulate: function (result) {
      let acumulatedResult = [];
      _.forEach(_.groupBy(result, "produtoId"), (itens) => {
        acumulatedResult.push(
          _.reduce(itens, (obj1, obj2) => {
            _.forEach(
              [
                "total_mi",
                "total_ex",
                "total_zf",
                "ipi",
                "piscofins",
                "icms",
                "aquisicoes_bens",
                "devolucoes_icmsst",
                "ipi_auferido",
                "icms_auferido",
                "total_liquido",
                "qtde_produzida",
                "devolucao_liquida",
                "data_portariacalc",
                "portariacalc",
              ],
              (key) => {
                obj1[key] = obj1[key] * 1 + obj2[key] * 1;
              }
            );
            return obj1;
          })
        );
      });
      return acumulatedResult;
    },
  },
  data: function () {
    return {
      cols: [
        {
          key: "_subtitle0",
          name: "Produto",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "abreviacao",
          name: "Abreviação",
          type: this.$fieldTypes.TEXT,
          editable: false,
          hideInform: true,
        },
        {
          key: "nome_produto",
          name: "Nome do Produto",
          type: this.$fieldTypes.TEXT,
          editable: false,
        },
        {
          key: "ncm",
          name: "NCM",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          editable: false,
        },
        {
          key: "tecnologia_nacional",
          name: "Tecnologina Nacional",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "portariacalc",
          name: "Portaria de Habilitação",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "data_portariacalc",
          name: "Data DOU",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "_subtitle1",
          name: "Faturamento, Exportações, Quantidade Produzida e Tributos",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "total_mi",
          name: "Faturamento Bruto no Mercado Interno",
          type: this.$fieldTypes.MONEY, nowrap: true,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "total_ex",
          name: "Exportações (R$)",
          type: this.$fieldTypes.MONEY, nowrap: true,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "total_zf",
          name: "Vendas para a ZFM (Zona Franca de Manaus x equiparadas a exportações) (R$)",
          type: this.$fieldTypes.MONEY, nowrap: true,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "qtde_produzida",
          name: "Quantidade Produzida",
          type: this.$fieldTypes.NUMBER,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "_faturamentoIPI",  // TODO temporariamente sempre ZERO / null
          name: "Faturamento com suspensão de IPI - Lei 10.637/2002 (R$)",
          type: this.$fieldTypes.NUMBER,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "_subtitle2",
          name: "Informar exclusivamente os valores efetivamente lançados na nota fiscal ou recolhidos às Fazendas Federal e Estaduais (impotos diretos sobre as vendas).",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "_subtitle2",
          name: "Recolhimento sobre o faturamento bruto",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "ipi",
          name: "IPI (R$)",
          type: this.$fieldTypes.MONEY, nowrap: true,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "devolucao_liquida",
          name: "Devoluções",
          type: this.$fieldTypes.MONEY, nowrap: true,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "devolucoes_icmsst",
          name: "ICMS (ST) (R$)",
          type: this.$fieldTypes.MONEY, nowrap: true,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
        {
          key: "_subtitle3",
          name: "Incentivo Auferido",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "icms_auferido",
          name: "ICMS",
          type: this.$fieldTypes.MONEY, nowrap: true,
          colSize: 6,
          editable: false,
          hideInTable: true,
        },
      ],
      opts: {},
    };
  },
};
</script>
